import React from 'react';
import posthog from 'posthog-js';

const CategoriesList = () => {
  const categories = [
    { label: 'All', url: '/' },
    { label: 'Tech', url: '/tech' },
    { label: 'Beauty', url: '/beauty' },
    { label: 'Health', url: '/health' },
    { label: 'Toxic-free', url: '/chemicalfree' },
  ];
  return (
    <div className="h-full items-end hidden lg:flex">
    <ul className="flex list-none justify-between text-sm">
      {categories.map((category, index) => (
        <li key={index} className="pr-4 py-4">
          <a href={category.url} className="text-textGray hover:underline hover:text-bluelogo"
             onClick={() => posthog.capture('category_click', { category: category.label })}>
            {category.label}
          </a>
        </li>
      ))}
    </ul>
    </div>
  );
};

export default CategoriesList;
